<template>
  <DeepDialog
    v-bind="$attrs"
    :max-width="500"
    :title="t('dialogs.support.title')"
    @close="clearAndClose"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <!-- Content -->
    <template #content>
      <v-form
        ref="formRef"
        v-model="isFormValid"
        :validate-on="isFormValid === undefined ? 'submit' : 'input'"
        @submit.prevent
      >
        <v-select
          v-model="form.type"
          clearable
          data-test-id="support-feedback-dropdown"
          :items="contactSupportItems"
          :label="t('dialogs.support.type_label')"
          :rules="[ruleRequired]"
        ></v-select>

        <FormSupport v-if="form.type === 'support'" v-model="form" />

        <FormFeedback v-else-if="form.type === 'feedback'" v-model="form" />
      </v-form>
    </template>
    <!-- /Content -->
    <!-- Actions -->
    <template #actions>
      <v-spacer />
      <VBtnSecondary name="btn-cancel" @click="clearAndClose">
        {{ t('actions.cancel') }}
      </VBtnSecondary>
      <VBtnPrimary
        :class="{ 'btn-wrap-text': xs }"
        data-test-id="send-btn"
        :disabled="attachmentsSizeTooLarge || !form.type"
        :loading="isLoading"
        name="btn-send"
        @click="sendRequest"
      >
        {{ t('buttons.send') }}
      </VBtnPrimary>
    </template>
    <!-- /Actions -->
  </DeepDialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

// components
import { DeepDialog } from '@deepcloud/deep-ui-lib'
import FormFeedback from './FormFeedback.vue'
import FormSupport from './FormSupport.vue'

// types & constants
import type { ContactFeedback, ContactSupport } from './const.ts'
import { SUPPORT_TYPES } from '@/api/types/deepadmin/users/users-support.ts'
import type { VForm } from 'vuetify/components'

// utilities
import { ruleRequired } from '@deepcloud/field-validators'
import { deepAdminUsersSupportAPI } from '@/api/deepadmin/users/users-support.ts'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useKeycloak } from '@/composables/auth/use-keycloak.ts'
import { useDisplay } from 'vuetify'

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const { xs } = useDisplay()

const isLoading = ref(false)
const attachmentsSizeTooLarge = ref(false)

const DEFAULT_FORM = {
  contact: '',
  text: '',
  type: undefined,
}

const form = ref<ContactFeedback | ContactSupport>({ ...DEFAULT_FORM })

const isFormValid = ref<boolean | undefined>(undefined)

const contactSupportItems = computed(() => [
  {
    id: SUPPORT_TYPES.SUPPORT,
    value: SUPPORT_TYPES.SUPPORT,
    title: t('dialogs.support.types.support.title'),
  },
  {
    id: SUPPORT_TYPES.FEEDBACK,
    value: SUPPORT_TYPES.FEEDBACK,
    title: t('dialogs.support.types.feedback.title'),
  },
])

const formRef = ref()

async function sendRequest() {
  const { valid } = await formRef.value.validate()
  if (valid) {
    const type = form.value.type
    isLoading.value = true
    await deepAdminUsersSupportAPI.post(form.value)
    isLoading.value = false
    clearAndClose()
    if (type === 'support') {
      toast.success(t('messages.success.support_requested'))
    } else if (type === 'feedback') {
      toast.success(t('messages.success.feedback_sent'))
    }
  } else {
    toast.error(t('error.validation.invalid'))
  }
}

function close() {
  emit('update:modelValue', false)
}

function clear() {
  form.value = {
    ...DEFAULT_FORM,
  }
  isFormValid.value = undefined
  formRef.value?.reset()
}

function clearAndClose() {
  clear()
  close()
}

const { user } = useKeycloak()
watch(
  () => form.value.type,
  (newValue) => {
    form.value = {
      ...DEFAULT_FORM,
      contact: user.value?.email,
      type: newValue,
    }
  },
)
</script>
