import { createI18n } from 'vue-i18n'

// app locales
import de from '@/locales/de.json'
import en from '@/locales/en.json'
import fr from '@/locales/fr.json'
import it from '@/locales/it.json'

const i18n = createI18n({
  legacy: false,
  locale: import.meta.env.VITE_I18N_LOCALE || 'de',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  warnHtmlMessage: false,
  messages: {
    de,
    en,
    fr,
    it,
  },
})

export default i18n
