import Keycloak, {
  type KeycloakLoginOptions,
  type KeycloakLogoutOptions,
  type KeycloakRegisterOptions,
  type KeycloakTokenParsed,
} from 'keycloak-js'
import { computed, ref } from 'vue'
import { useEventHook } from '@/composables/use-event-hook'
import { KeycloakKeyIds } from '@/constants/auth'
import { eraseCookie, getCookie, setCookie } from '@/utils/cookies'
import * as Sentry from '@sentry/vue'
import { isLocalOrDevEnv, isSentryEnabled } from '@/utils/helpers/env.ts'
import i18n from '@/plugins/i18n.ts'

export type KeycloakRegisterOptionsCustom = Omit<
  KeycloakRegisterOptions,
  'prompt'
> & {
  prompt?: 'none' | 'login' | 'consent' | 'force_login_hint'
}

const initOptions = {
  url: `${import.meta.env.VITE_IDP_URL}/${import.meta.env.VITE_IDP_URL_AUTH}`,
  realm: import.meta.env.VITE_IDP_REALM,
  clientId: import.meta.env.VITE_IDP_CLIENT_ID,
}

const keycloak = new Keycloak(initOptions)

export const isAuthenticated = ref<boolean>()
export const token = ref<string>()
export const tokenType = ref<string>()

export const bearer = computed(() => `${tokenType.value} ${token.value}`)

export interface UserAuth extends KeycloakTokenParsed {
  'locale': string
  'allowed-origins': string[]
  'beta_features'?: string[]
  'display_name': string
  'email': string
  'email_verified': boolean
  'family_name': string
  'given_name': string
  'name': string
  'preferred_username': string
  'roles': string[]
  'scope': string
  'sid': string
  'totp': boolean
  'typ': string
}

export const user = ref<UserAuth>()

// Keycloak Events
const onAuthRefreshSuccessEvent = useEventHook()
const onTokenExpiredEvent = useEventHook()
const onAuthLogoutEvent = useEventHook()

keycloak.onAuthRefreshSuccess = () => {
  onAuthRefreshSuccessEvent.trigger()
}
keycloak.onTokenExpired = () => {
  onTokenExpiredEvent.trigger()
}
keycloak.onAuthLogout = () => {
  onAuthLogoutEvent.trigger()
}

// Handle Session
export const sessionId = ref<string | undefined>(undefined)
const keycloakCookieID = `${import.meta.env.VITE_ENVIRONMENT}.${
  KeycloakKeyIds.KC_SESSION_ID
}`

// Access Token
function saveAccessTokenInSessionStorage(accessToken) {
  sessionStorage.setItem(KeycloakKeyIds.KC_TOKEN, accessToken)
}

function updateAccessTokenInSessionStorage() {
  saveAccessTokenInSessionStorage(keycloak.token)
}

function clearAccessTokenInSessionStorage() {
  sessionStorage.removeItem(KeycloakKeyIds.KC_TOKEN)
}

// Refresh Token
function saveRefreshTokenInSessionStorage(refreshToken) {
  sessionStorage.setItem(KeycloakKeyIds.KC_REFRESH_TOKEN, refreshToken)
}

function updateRefreshTokenInSessionStorage() {
  saveRefreshTokenInSessionStorage(keycloak.refreshToken)
}

function clearRefreshTokenInSessionStorage() {
  sessionStorage.removeItem(KeycloakKeyIds.KC_REFRESH_TOKEN)
}

// Session ID
function saveSessionId(sessionId) {
  const cookieSessionIdDomain = import.meta.env.VITE_DEEPBOX_COOKIE_DOMAIN
  setCookie(
    keycloakCookieID,
    sessionId,
    undefined,
    undefined,
    cookieSessionIdDomain,
  )
}

function updateSessionId() {
  saveSessionId(sessionId.value)
}

function clearSessionId() {
  eraseCookie(keycloakCookieID)
}

function updateKcValues() {
  user.value = keycloak?.tokenParsed as UserAuth
  token.value = keycloak?.token
  tokenType.value = user.value?.typ
  isAuthenticated.value = keycloak.authenticated
}

export async function updateToken(minValidity = 70) {
  try {
    const didChange = await keycloak.updateToken(minValidity)
    if (didChange) {
      console.log('TOKEN UPDATED')
      // Update store
      if (keycloak.refreshToken) {
        updateAccessTokenInSessionStorage()
        updateRefreshTokenInSessionStorage()
        if (sessionId.value) {
          updateSessionId()
        }
        updateKcValues()
      }
    } else {
      console.log('TOKEN STILL VALID')
      if (sessionId.value) {
        updateSessionId()
      }
      // Do nothing
    }
    return Promise.resolve(didChange)
  } catch {
    return Promise.reject(new Error('Error on update token.'))
  }
}

let keycloakInterval: NodeJS.Timeout | null = null
const removeKeycloakRefreshInterval = () => {
  if (keycloakInterval) {
    clearInterval(keycloakInterval)
  }
}

const createKeycloakRefreshInterval = () => {
  removeKeycloakRefreshInterval()
  console.log('AUTH(KC): init updateToken interval every 3000ms')
  keycloakInterval = setInterval(updateToken, 3000)
}

export function useKeycloak() {
  function logout(options?: KeycloakLogoutOptions | undefined) {
    let logoutOptions: KeycloakLogoutOptions = {}
    if (options) {
      logoutOptions = { ...options }
    }

    const redirectUri = import.meta.env.VITE_IDP_REDIRECT_URI
    if (!options?.redirectUri) {
      logoutOptions = {
        ...logoutOptions,
        redirectUri,
      }
    }
    return keycloak.logout(logoutOptions)
  }

  function login(options?: KeycloakLoginOptions | undefined) {
    return keycloak.login({
      redirectUri:
        options?.redirectUri || import.meta.env.VITE_IDP_REDIRECT_URI,
      scope:
        options?.scope || import.meta.env.VITE_IDP_SCOPES.replaceAll(',', ' '),
      locale: i18n.global?.locale?.value || 'de',
      ...options,
    })
  }

  function register(options?: KeycloakRegisterOptionsCustom) {
    return keycloak.register({
      redirectUri:
        options?.redirectUri || import.meta.env.VITE_IDP_REDIRECT_URI,
      scope:
        options?.scope || import.meta.env.VITE_IDP_SCOPES.replaceAll(',', ' '),
      locale: i18n.global?.locale?.value || 'de',
      ...options,
    } as KeycloakRegisterOptions)
  }

  function init(onAuthenticatedCallback: (authenticated: boolean) => void) {
    keycloak
      .init({
        enableLogging: isLocalOrDevEnv(),
        timeSkew: 0,
        onLoad: 'check-sso',
        checkLoginIframe: false,
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        scope: import.meta.env.VITE_IDP_SCOPES.replaceAll(',', ' '),
        token: sessionStorage.getItem(KeycloakKeyIds.KC_TOKEN) || undefined,
        refreshToken:
          sessionStorage.getItem(KeycloakKeyIds.KC_REFRESH_TOKEN) || undefined,
        pkceMethod: 'S256',
      })
      .then((authenticated: boolean) => {
        console.log('Keycloak initialized')
        if (authenticated) {
          updateKcValues()
          if (isSentryEnabled()) {
            Sentry.setUser({
              id: user.value?.sub,
              display_name: user.value?.display_name,
            })
          }
          if (keycloak.refreshToken) {
            updateAllStorageIds()
          }
          createKeycloakRefreshInterval()
        } else {
          clearAllStorageIds()
        }
        onAuthenticatedCallback(authenticated)
      })
      .catch((e) => {
        console.error('Keycloak init failed ', e)
        clearAllStorageIds()
      })
  }

  function addListener(event, fn) {
    switch (event) {
      case 'onAuthRefreshSuccess':
        onAuthRefreshSuccessEvent.on(fn)
        break
      case 'onTokenExpired':
        onTokenExpiredEvent.on(fn)
        break
      case 'onAuthLogout':
        onAuthLogoutEvent.on(fn)
        break
      default:
        console.error(`Not supported keycloak event: ${event}`)
    }
  }

  function removeListener(event, fn) {
    switch (event) {
      case 'onAuthRefreshSuccess':
        onAuthRefreshSuccessEvent.off(fn)
        break
      case 'onTokenExpired':
        onTokenExpiredEvent.off(fn)
        break
      case 'onAuthLogout':
        onAuthLogoutEvent.off(fn)
        break
      default:
        console.error(`Not supported keycloak event: ${event}`)
    }
  }

  // helpers
  function hasRealmRole(role: string) {
    return keycloak.hasRealmRole(role)
  }

  function hasRealmRoles(roles: string[]) {
    roles.some((role) => hasRealmRole(role))
  }

  function hasBetaFeature(betaFeature: string) {
    if (!user.value?.beta_features || user.value?.beta_features.length === 0)
      return false
    return user.value.beta_features?.includes(betaFeature)
  }

  function listenIfSessionIdCookieExists(callback, interval = 1000) {
    let sessionIdCookieLast = getCookie(keycloakCookieID)
    setInterval(() => {
      const sessionIdCookie = getCookie(keycloakCookieID)
      if (sessionIdCookie !== sessionIdCookieLast) {
        try {
          callback({
            oldValue: sessionIdCookieLast,
            newValue: sessionIdCookie,
          })
        } finally {
          sessionIdCookieLast = sessionIdCookie
        }
      }
    }, interval)
  }

  // Handle all ids at the same time
  function clearAllStorageIds() {
    clearAccessTokenInSessionStorage()
    clearRefreshTokenInSessionStorage()
    clearSessionId()
  }

  function updateAllStorageIds() {
    updateAccessTokenInSessionStorage()
    updateRefreshTokenInSessionStorage()
    updateSessionId()
  }

  return {
    keycloak,
    bearer,
    isAuthenticated,
    token,
    tokenType,
    user,
    init,
    logout,
    login,
    register,
    updateToken,
    listenIfSessionIdCookieExists,
    addListener,
    removeListener,
    hasBetaFeature,
    hasRealmRole,
    hasRealmRoles,
  }
}
