<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="form.contact"
        :hint="t('hints.we_may_contact_you_for_further_questions')"
        :label="t('labels.email')"
        name="field-form-feedback-email"
        persistent-hint
        :placeholder="t('placeholders.contact_email')"
        :rules="[ruleRequired, ruleEmail]"
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.text"
        :counter="formMessageMaxLength"
        :label="t('labels.feedback_message')"
        name="field-feedback-message"
        :placeholder="t('placeholders.enter_your_feedback')"
        :rules="[
          ruleRequired,
          (v) => ruleMaxLength(v, { length: formMessageMaxLength }),
        ]"
        variant="outlined"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { type PropType } from 'vue'

// types & constants
import type { ContactFeedback } from './const.ts'

// utilities
import {
  ruleEmail,
  ruleMaxLength,
  ruleRequired,
} from '@deepcloud/field-validators'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const form = defineModel({
  type: Object as PropType<ContactFeedback>,
  default: () => ({
    contact: '',
    text: '',
    type: 'feedback',
  }),
})

const formMessageMaxLength = 512
</script>
