import { type App, watchEffect } from 'vue'
import {
  DeepMsgBoxPlugin,
  locales,
  setI18nLocale as setI18nLocaleDeepUiLib,
} from '@deepcloud/deep-ui-lib'
import {
  localeDE,
  localeEN,
  localeFR,
  localeIT,
  setI18nLocale as setI18nLocaleFieldValidators,
} from '@deepcloud/field-validators'
import i18n from '@/plugins/i18n.ts'

// Add DeepUiLib locales to app vue-i18n instance
i18n.global.mergeLocaleMessage('de', locales.de)
i18n.global.mergeLocaleMessage('fr', locales.fr)
i18n.global.mergeLocaleMessage('it', locales.it)
i18n.global.mergeLocaleMessage('en', locales.en)

// Add DeepFieldValidators locales to app vue-i18n instance
i18n.global.mergeLocaleMessage('de', localeDE)
i18n.global.mergeLocaleMessage('fr', localeFR)
i18n.global.mergeLocaleMessage('it', localeIT)
i18n.global.mergeLocaleMessage('en', localeEN)

// update plugins i18n locale
watchEffect(() => {
  setI18nLocaleFieldValidators(i18n.global.locale.value)
  setI18nLocaleDeepUiLib(i18n.global.locale.value)
})

export default {
  install: (app: App) => {
    console.log('installing deep-ui plugin')

    app.use(DeepMsgBoxPlugin, {
      msgBox: {
        i18n: i18n.global,
        closable: true,
        btnActions: {
          cancel: {
            name: 'cancel',
            titleI18nKey: 'buttons.cancel',
            color: 'primary',
            variant: 'outlined',
          },
          confirm: {
            name: 'confirm',
            titleI18nKey: 'buttons.confirm',
            color: 'primary',
            variant: 'flat',
          },
        },
        dialogProps: {
          persistent: true,
          width: 450,
        },
      },
    })
    console.log('installing deep-ui: DeepMsgBoxPlugin plugin')
  },
}
