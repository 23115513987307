export function getAppLocaleFormatted(
  locale: string,
  availableLocales: string[],
  fallbackLocale = 'de',
) {
  if (!locale) {
    locale = fallbackLocale
  }
  // 1. locale is only 2 chars (de, en, fr, it)
  const localeLowerCaseIdx = availableLocales.findIndex((a) =>
    a.startsWith(locale),
  )
  if (localeLowerCaseIdx !== -1) {
    return availableLocales[localeLowerCaseIdx]
  }
  // 2. locale has wrong format (de-ch, en-gb (or en-us), fr-ch, it-ch)
  // or locale has wrong format (deCH, enGB (or enUS), frCH, itCH)
  const localeWrongFormatWithoutHyphenMinusIdx = locale.indexOf('-')
  if (localeWrongFormatWithoutHyphenMinusIdx !== -1) {
    const localeLowerCaseIdx = availableLocales.findIndex(
      (a) =>
        a.toLowerCase().substring(0, 2) ===
        locale.toLowerCase().substring(0, 2),
    )
    if (localeLowerCaseIdx !== -1) {
      return availableLocales[localeLowerCaseIdx]
    }
  } else {
    const localeLowerCaseIdx = availableLocales.findIndex(
      (a) =>
        a.replace('-', '').substring(0, 2) ===
        locale.toLowerCase().substring(0, 2),
    )
    if (localeLowerCaseIdx !== -1) {
      return availableLocales[localeLowerCaseIdx]
    }
  }

  // if not match, returns the app default locale
  return fallbackLocale
}
