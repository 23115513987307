import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export function useKnowledgeCenter() {
  const { locale } = useI18n()

  const baseUrl = import.meta.env.VITE_KNOWLEDGE_CENTER_URL

  const link = computed(() => {
    let url = baseUrl
    if (locale.value !== 'de') {
      url = `${url}/${locale.value}`
    }
    return url
  })

  const linkDeepBox = computed(() => `${link.value}/topic/deepbox/`)

  const linkSubscriptions = computed(() => {
    let url = link.value
    switch (locale.value) {
      case 'en':
        url += '/topic/deepcloud/subscription-en/'
        break
      case 'fr':
        url += '/topic/deepcloud/subscription-fr/'
        break
      case 'it':
        url += '/topic/deepcloud/subscription-it/'
        break
      default:
        url += '/topic/deepcloud/subscription/'
    }
    return url
  })

  const linkBoxTrash = computed(() => {
    const urls = {
      de: 'https://support.deepcloud.swiss/article/wo-ist-der-papierkorb/',
      en: 'https://support.deepcloud.swiss/en/article/where-is-the-trash/',
      fr: 'https://support.deepcloud.swiss/fr/article/ou-est-la-corbeille-a-papier/',
      it: 'https://support.deepcloud.swiss/it/article/dove-il-cestino-della-carta/',
    }

    return urls[locale.value] || urls.de
  })

  return {
    link,
    linkDeepBox,
    linkSubscriptions,
    linkBoxTrash,
  }
}
