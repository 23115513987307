<template>
  <v-layout>
    <!-- APP BAR -->
    <TheAppBar
      :hide-app-switch="hideAppSwitch"
      :hide-logo="display.smAndDown.value && isDashboardRoute"
      :is-mobile="device.isMobile"
    >
      <template #desktop-prepend-end>
        <MenuOrganizationSelection
          v-if="isAuthenticated && isDashboardRoute"
          :hide-menu-icon="
            deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
          "
          :is-menu-disabled="
            deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
          "
          :organizations="deepAdminUsersMeStore.getUserSelectableOrganizations"
          :selected-organization="deepAdminUsersMeStore.selectedOrganization"
          @change:organization="onChangeOrganization"
        />
      </template>
      <template #desktop-append>
        <template v-if="isAuthenticated">
          <AppBarFavoritesMenu />
          <AppBarRecentMenu />
          <AppBarServiceMenu
            v-if="selectedOrganizationId"
            :current-organization="deepAdminUsersMeStore.selectedOrganization"
          />
        </template>
        <span></span>
      </template>

      <!-- MOBILE -->
      <template v-if="globalStore.mobileSearch" #mobile-prepend>
        <v-btn
          v-if="globalStore.mobileSearch"
          icon="fas fa-arrow-left"
          @click.prevent.stop="goBack"
        >
        </v-btn>
      </template>
      <template #mobile-content>
        <MenuOrganizationSelection
          v-if="!globalStore.mobileSearch && isDashboardRoute"
          :hide-menu-icon="
            deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
          "
          :is-menu-disabled="
            deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
          "
          :organizations="deepAdminUsersMeStore.getUserSelectableOrganizations"
          :selected-organization="deepAdminUsersMeStore.selectedOrganization"
          @change:organization="onChangeOrganization"
        />
        <v-text-field
          v-else-if="isDashboardRoute"
          v-model="searchField"
          autofocus
          clearable
          density="compact"
          hide-details
          name="field-search"
          :placeholder="t('placeholders.search_box')"
          width="100%"
        />
      </template>
      <template v-if="isDashboardRoute" #mobile-append>
        <v-btn
          v-if="isAuthenticated"
          class="mx-2"
          density="comfortable"
          icon="far fa-magnifying-glass"
          @click="globalStore.mobileSearch = !globalStore.mobileSearch"
        >
        </v-btn>
      </template>
      <!-- /MOBILE -->
    </TheAppBar>
    <!-- /APP BAR -->

    <v-main class="bg-background-grey">
      <ContainerLayoutWrapper>
        <slot>
          <router-view />
        </slot>
        <!-- Overused subscription blocking overlay -->
        <OverlayOverusedSubscription />
        <!-- /Overused subscription blocking overlay -->
      </ContainerLayoutWrapper>
    </v-main>
  </v-layout>
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue'
import debounce from 'lodash/debounce'
import MenuOrganizationSelection from '@/components/menu/MenuOrganizationSelection.vue'
import AppBarFavoritesMenu from '@/components/appbar/AppBarFavoritesMenu.vue'
import AppBarRecentMenu from '@/components/appbar/AppBarRecentMenu.vue'
import OverlayOverusedSubscription from '@/components/overlays/OverlayOverusedSubscription.vue'
import AppBarServiceMenu from '@/components/appbar/AppBarServiceMenu.vue'
import TheAppBar from '@/components/appbar/TheAppBar.vue'
import ContainerLayoutWrapper from '@/components/containers/ContainerLayoutWrapper.vue'

import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import { useGlobalStore } from '@/stores/global/global'
import { useDeepBoxOverviewStore } from '@/stores/deepbox/overview'
import { useDeepAdminUsersCompanyAccountingStore } from '@/stores/deepadmin/users/users-company-accounting'
import { useRoute, useRouter } from 'vue-router'
import { DeviceKey } from '@/plugins/device-detector-js.ts'
import {
  isAuthenticated,
  useKeycloak,
} from '@/composables/auth/use-keycloak.ts'
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'

const device = inject(DeviceKey)
const router = useRouter()
const route = useRoute()
const display = useDisplay()
const { t } = useI18n()

const searchField = ref('')

const deepBoxOverviewStore = useDeepBoxOverviewStore()
const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
const globalStore = useGlobalStore()
const deepAdminUsersCompanyAccountingStore =
  useDeepAdminUsersCompanyAccountingStore()

const selectedOrganizationId = computed(
  () => deepAdminUsersMeStore.selectedOrganizationId || '',
)

watch(
  () => selectedOrganizationId.value,
  (newValue) => {
    if (newValue?.length) {
      deepAdminUsersCompanyAccountingStore.fetchCompanyAccounting(newValue)
    }
  },
  { immediate: true },
)

const isDashboardRoute = computed(() => route.name === 'organization')

watch(
  () => searchField.value,
  (newValue) => {
    if (newValue?.length > 0) {
      searchBoxesDebounced(newValue)
    } else {
      globalStore.setShowMobileSearchResult(false)
    }
  },
)

const searchBoxesDebounced = debounce(async (search: string) => {
  await deepBoxOverviewStore.fetchOverviewFull({
    companyId: selectedOrganizationId.value,
    query: search,
  })
  globalStore.setShowMobileSearchResult(true)
}, 500)

async function onChangeOrganization(organization: UserMeOrganization) {
  if (selectedOrganizationId.value !== organization.group_id) {
    deepAdminUsersCompanyAccountingStore.$reset()
    deepAdminUsersMeStore.selectOrganization(organization)

    void router.push({
      name: 'organization',
      params: { organization: organization.group_id },
    })
  }
}

async function goBack() {
  searchField.value = ''
  globalStore.mobileSearch = false
  globalStore.setShowMobileSearchResult(false)
  await deepBoxOverviewStore.fetchOverviewFull({
    companyId: selectedOrganizationId.value,
  })
}

const { isAuthenticated } = useKeycloak()
const hideAppSwitch = computed(() => !isAuthenticated.value)
</script>
<style lang="scss" scoped>
.v-menu__content--fixed {
  border-radius: 12px;
}

.fav-recent-menu-btn {
  font-size: 14px;
  font-weight: 500;
  color: #212529;
}

.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
