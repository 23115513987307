<template>
  <v-row>
    <v-col cols="12">
      <i18n-t
        class="text-grey-darken-1 text-body-2"
        keypath="hints.check_knowledge_base"
        tag="div"
      >
        <template #link>
          <AppLinkExternal
            :href="linkKnowledgeCenter"
            :text="t('hints.check_knowledge_base_link_text')"
          />
        </template>
      </i18n-t>
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="form.contact"
        :hint="t('hints.we_may_contact_you_for_further_questions')"
        :label="t('labels.email')"
        name="field-form-support-email"
        persistent-hint
        :placeholder="t('placeholders.contact_email')"
        :rules="[ruleRequired, ruleEmail]"
        variant="outlined"
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.text"
        :counter="formMessageMaxLength"
        :label="t('labels.request_message')"
        name="field-support-message"
        :placeholder="t('placeholders.enter_support_request')"
        :rules="[
          ruleRequired,
          (v) => ruleMaxLength(v, { length: formMessageMaxLength }),
        ]"
        variant="outlined"
      />
    </v-col>

    <v-col cols="12">
      <span>
        {{ t('labels.attachments') }}
      </span>
      <FileUpload
        v-model="form.files"
        @file-too-large="attachmentsSizeTooLarge = $event"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { type PropType, ref } from 'vue'
// components
import AppLinkExternal from '@/components/app/AppLinkExternal.vue'
import FileUpload from '@/components/FileUpload.vue'
// types & constants
import type { ContactSupport } from './const.ts'
// composable
import { useKnowledgeCenter } from '@/composables/use-knowledge-center.ts'
// utilities
import {
  ruleEmail,
  ruleMaxLength,
  ruleRequired,
} from '@deepcloud/field-validators'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const form = defineModel({
  type: Object as PropType<ContactSupport>,
  default: () => ({
    files: [],
    contact: '',
    text: '',
    type: 'support',
  }),
})

const { linkDeepBox: linkKnowledgeCenter } = useKnowledgeCenter()

const attachmentsSizeTooLarge = ref(false)
const formMessageMaxLength = 512
</script>
