import { useLocale } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { useDeepAdminUsersMeLanguageStore } from '@/stores/deepadmin/users/users-me-language.ts'
import { toast } from 'vue-sonner'
import { setApisAcceptLanguageHeader } from '@/api/interceptors/api-instances'
import { LS_LOCALE_KEY } from '@/constants/auth'
import { useKeycloak } from '@/composables/auth/use-keycloak'
import { getAppLocaleFormatted } from '@/utils/helpers/app-locale.ts'

export function useAppLocale() {
  const { current: localeVuetify } = useLocale()
  const { locale: localeI18n, availableLocales } = useI18n()
  const { isAuthenticated, updateToken } = useKeycloak()

  const _setPluginsLocale = (locale: string) => {
    const localeFormatted = getAppLocaleFormatted(locale, availableLocales)
    if (!localeFormatted) return
    localeI18n.value = localeFormatted
    localeVuetify.value = localeFormatted
    setApisAcceptLanguageHeader(localeFormatted)
    document.documentElement.lang = localeFormatted
    localStorage.setItem(`${LS_LOCALE_KEY}`, localeFormatted)
    console.log('current locale: ', localeFormatted)
  }

  const deepAdminUsersMeLanguageStore = useDeepAdminUsersMeLanguageStore()

  function changeAppLocale(locale: string) {
    console.log('Change APP locale: ', locale)
    _setPluginsLocale(locale)
  }

  async function setAppLocale(locale: string) {
    changeAppLocale(locale)

    if (isAuthenticated.value) {
      try {
        const localeIsoCode = getAppLocaleFormatted(locale, availableLocales)
        await deepAdminUsersMeLanguageStore.storeUserLanguage(localeIsoCode)
        await updateToken()
      } catch {
        toast.error('Error on set user lang')
      }
    }
  }

  function getLanguageTextByIsoCode(isoCode: string) {
    switch (isoCode) {
      case 'de':
        return 'Deutsch'
      case 'en':
        return 'English'
      case 'fr':
        return 'Français'
      case 'it':
        return 'Italiano'
      default:
        return ''
    }
  }

  return {
    changeAppLocale,
    setAppLocale,
    getLanguageTextByIsoCode,
  }
}
