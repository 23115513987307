/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import initializeRouter from '@/router'
import vuetify from './vuetify.ts'
import pinia from '@/stores'
import i18n from '@/plugins/i18n.ts'
import sentry from './sentry.ts'
import matomo from './vue-matomo.ts'
import { createHead } from '@unhead/vue'
import deviceDetectorJs from '@/plugins/device-detector-js.ts'
import deepUiLib from '@/plugins/deep-ui-lib.ts'

// Global Components
import FieldComponents from '@/plugins/field.ts'
import AppLoader from '@/components/app/AppLoader.vue'
import { JsonTreeView } from 'json-tree-view-vue3'
import 'json-tree-view-vue3/dist/style.css'

// Types
import type { App } from 'vue'
import { markRaw } from 'vue'

export function registerPlugins(app: App) {
  app.use(vuetify)
  app.use(pinia)
  app.use(i18n)
  app.use(matomo)
  app.use(sentry)
  const head = createHead()
  app.use(head)
  app.use(deviceDetectorJs, { refreshOnResize: true })
  app.use(deepUiLib)

  // Global components
  // auto register components by folder
  FieldComponents.install(app)
  app.component('AppLoader', AppLoader)
  app.component('JsonTreeView', JsonTreeView)

  // Init router after all other plugins
  // https://github.com/dsb-norge/vue-keycloak-js/issues/94#issuecomment-1794403391
  // https://github.com/keycloak/keycloak/issues/14742#issuecomment-1663069438
  const router = initializeRouter()

  // inject route to pinia in order to have access to the router instance inside pinia stores
  // https://stackoverflow.com/questions/70681667/cant-use-vue-router-and-pinia-inside-a-single-store
  pinia.use(({ store }) => {
    store.router = markRaw(router)
  })

  // Init router after all other plugins
  // https://github.com/dsb-norge/vue-keycloak-js/issues/94#issuecomment-1794403391
  // https://github.com/keycloak/keycloak/issues/14742#issuecomment-1663069438
  app.use(router)
}
